import React from "react";

import {StaticImage } from "gatsby-plugin-image";

import ButtonSolid from "../../components/Button/ButtonSolid";
// import PortableText from "../../components/Blog/portableText";
// import ButtonGhost from "../../components/Button/ButtonGhost";

const AboutUsSection1 = ({
  sectionTitle,
  sectionDesc,

}) => {
  return (
    <section className="bg-tertiary-50 relative md:pt-0 ">
      <div className="container hidden md:block">
        <div className="grid gap-y-12 gap-x-[20px] md:grid-cols-2">
          <div className="md:py-24 lg:py-36 lg:pr-20">
            <h1>{sectionTitle}</h1>
            <p>
              MesaView Senior Assisted Living in the San Diego suburb of La Mesa
              is dedicated to offering compassionate, individualized senior care
              and support services for residents living with or without
              Alzheimer’s disease or other types of dementia.
            </p>
            <p>
              We value respect, dignity, trust, and safety and hire only the
              best caregivers in the industry to ensure our values are upheld.
              When you are at MesaView, you are part of the family. Our staff is
              well-trained and considerate, and we provide around-the-clock care
              in our charming and intimate home.
            </p>
            <p>
              We’ve also made significant renovations throughout the community
              after taking ownership in 2018. We updated the outdoor patio
              space, created a new outdoor patio dining area, expanded
              hallways/walkways and updated ADA ramps, installed a new,
              state-of-the-art kitchen, and updated residents’ rooms from floor
              to ceiling.
            </p>
            <ButtonSolid
              modal="modal-contact"
              text="Contact Us"
              className={
                "mr-auto max-w-[180px] text-[16px] font-normal lg:ml-0"
              }
            />
          </div>
          <div className="relative md:h-full md:py-24  ">
            <div className="">
              <StaticImage
                src="../../images/4.0 About/1.0 About.jpg"
                loading="eager"
                placeholder="none"
              />
              {/* <GatsbyImage
                class="rounded-[8px]"
                image={contents.asset.gatsbyImageData}
                alt={sectionTitle}
                className="h-full w-full"
              /> */}
            </div>
          </div>
        </div>
      </div>
      {/* start mobile hero */}
      <div className="mb-20 block md:mb-32 md:hidden ">
        <div className="grid gap-y-12 md:grid-cols-2 ">
          <div className="px-8 text-center md:py-24 lg:py-36 ">
            <h1>{sectionTitle}</h1>
            <p>
              MesaView Senior Assisted Living in the San Diego suburb of La Mesa
              is dedicated to offering compassionate, individualized senior care
              and support services for residents living with or without
              Alzheimer’s disease or other types of dementia.
            </p>
            <p>
              We value respect, dignity, trust, and safety and hire only the
              best caregivers in the industry to ensure our values are upheld.
              When you are at MesaView, you are part of the family. Our staff is
              well-trained and considerate, and we provide around-the-clock care
              in our charming and intimate home.
            </p>
            <p>
              We’ve also made significant renovations throughout the community
              after taking ownership in 2018. We updated the outdoor patio
              space, created a new outdoor patio dining area, expanded
              hallways/walkways and updated ADA ramps, installed a new,
              state-of-the-art kitchen, and updated residents’ rooms from floor
              to ceiling.
            </p>
            <ButtonSolid
              modal="modal-contact"
              text="Contact Us"
              className={
                "mr-auto max-w-[180px] text-[16px] font-normal lg:ml-0"
              }
            />
          </div>
          <div className="relative md:h-full md:py-24  ">
            <div className="">
              <StaticImage
                src="../../images/4.0 About/1.0 About.jpg"
                loading="eager"
                placeholder="none"
                layout="constrained"
                className=""
              />
            </div>
          </div>
        </div>
      </div>
      {/* end mobile hero */}
    </section>
  );
};

export default AboutUsSection1;
