import React from "react";
// import ButtonSolid from "../../components/Button/ButtonSolid";
import { StaticImage } from "gatsby-plugin-image";

const AboutUsSection2 = ({
  headingLevel,
  className,
  sectionTitle,
  sectionDesc,
}) => {
  return (
    <>
      <section className=" relative -top-[114px] max-h-[450px] overflow-hidden md:-top-[50px] lg:h-full ">
        <div className="relative mb-20 w-full ">
          {/* START SECTION WRAPPER */}
          <div className="relative flex flex-col items-center justify-center justify-items-center">
            {/* START UNDERLAY CONTAINER */}
            <div className="aboslute bottom-0  ">
              <StaticImage
                src="../../images/4.0 About/Mission Heading.jpg"
                className=""
              />
            </div>
            {/* END OF UNDERLAY CONTAINER */}
            {/* START CONTENT */}
            <div className="container absolute  top-8 mx-auto  mt-0 flex h-full items-center justify-center justify-items-center md:top-0  md:pt-0 ">
              <div className="grid gap-y-8">
                <div className=" mx-auto text-center lg:max-w-[65%]">
                  <h2 className="text-gray-800">Our Mission at MesaView</h2>
                  <p className="text-gray-800">
                    At MesaView, we’re committed to showing kindness and
                    understanding and believe in the power and strength of all
                    aspects of life—physical, social, and spiritual. Our
                    well-trained and considerate senior care staff in La Mesa
                    works around the clock to care for residents in our charming
                    and intimate home.
                  </p>
                </div>
              </div>
            </div>
            {/* END OF CONTENT */}
          </div>
          {/* END OF SECTION WRAPPER */}
        </div>

        {/* <div className="absolute bottom-0 left-0 hidden w-full overflow-hidden  pt-18 md:block  "></div>
        <div className="absolute bottom-0 left-0 block h-full w-full md:hidden   ">
          <StaticImage
            src="../../images/4.0 About/mission-mobile.jpg"
            objectFit="cover"
          />
        </div> */}
        {/* <div className="container relative mx-auto mt-0 pt-18 md:pt-0">
          <div className="grid gap-y-8">
            <div className=" mx-auto text-center lg:max-w-[65%]">
              <h2 className="text-white">{sectionTitle}</h2>
              <p className="text-white">{sectionDesc}</p>
            </div>
          </div>
        </div> */}
      </section>
    </>
  );
};

export default AboutUsSection2;
